@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  /* to fix bouncing scroll */
  /* https://stackoverflow.com/questions/21209223/remove-bounce-on-scroll-in-browser-issue-with-positionfixed-div */
  width: 100%;
  height: 100%;
}

/*TODO: fix after we put a general layout */
#root {
  height: 100%;
  /* overflow-y: scroll; */
}

h2.ant-typography,
.ant-typography h2 {
  font-size: 28px !important;
}

@media (max-width: 667px) and (orientation: portrait) {
  .ant-card {
    min-width: 100%;
    max-width: 100%;
    /* background: transparent !important; */
  }
  /* .ant-card-bordered {
    border: none !important;
  } */
}

@media (min-width: 1024px) and (orientation: landscape) {
  .ant-card {
    min-width: 50%;
  }
}

@media (min-width: 667px) {
  .withContentPaddingDesktop {
    padding: 50px 50px;
  }
}

/* Ant Design Theme override */

.ant-card {
  border-radius: 4px !important;
}
.ant-btn-primary {
  min-width: 200px;
  color: #fff;
  background-color: #1890ff !important;
  border: none !important;
  border-radius: 4px !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-modal-wrap {
  /* margin-top-bottom: 8px */
  max-height: calc(100vh - 16px);
}

.ant-modal-header,
.ant-modal-footer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
}

.ant-modal-header > .ant-btn,
.ant-modal-footer > .ant-btn {
  margin: 10px 0 0 0 !important;
}
.ant-modal-footer {
  padding-bottom: 30px !important;
}

/* This might not to be the right way of overriding */
.confirm-modal-wrapper.ant-modal-confirm .ant-modal-body {
  padding: 24px;
}
